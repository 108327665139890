import React from 'react';
import './App.css';

import Header from './components/Header';
import About from './components/About';
import Work from './components/Work';
import Contact from './components/Contact';
import Navbar from './components/Navbar';
import ChainsawGif from './components/ChainsawGif'; // import the AnimatedGIF component

function App() {
  return (
    <div className="App">
      <Navbar></Navbar>
      <div className="App-header">
      <Header></Header>
      </div>

      <div className='About'>
        <About></About>     
      </div>
      <div className='work'>
        <Work></Work>
      </div>
      <div className='contact'>
        <Contact></Contact>
      </div>      
      <div className='threed' > 
        <ChainsawGif src="path/to/my-gif.gif" alt="My GIF"/>
        {/* <AnimatedGIF /> */}
        {/* <RotatingObject />  */}
        {/* <RotatingObjectGif />  */}
      </div>
    </div>
  );
}

export default App;
