import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import data from '../yourdata';

class About extends Component {
    state = {  }
    render() { 
        return (<div >
            
            <h1 id='about-title'><Fade bottom cascade>About.</Fade></h1>
             <div className='about-content'>
                 </div> {data.ShowAboutImageTwo ? <img src={data.aboutImageTwo} id='imgtwo' alt='about image two'></img> : null}   
            <div className='about-image-text'>
                        
               {data.ShowAboutImage ? <img src={data.aboutImage} id='imgone' alt='about image'></img> : null}
            <Fade bottom id='fade'>
                    {/* <p>{data.abouttext}</p>
                     */}
                     <p>Ch4ins4w is an electronic musician and producer hailing from the underground electronic music scene. With a focus on IDM, breakcore, footwork, and other experimental genres, Ch4ins4w has been gaining a reputation for his unique sound and innovative approach to electronic music.
                        <br/><br/>
                        With 6 full-length albums and 1 EP already under his belt, Ch4ins4w has been making waves in the electronic music community. His music has been featured on various music publications and websites, including Felt Zine, where his single "Simulation Run" was listed as one of their favorite tracks of 2019.
                        <br/><br/>
                        Amidst the challenges faced in 2020, the talented artist and his collective Kalibrplus showcased a unique and unprecedented electronic performance in Kuwait, which earned them great success. Their extraordinary feat caught the attention of various prestigious publications, such as Scene Noise Magazine and Arab News, as well as garnered the interest of BBC Radio. Following this accomplishment, the artist went on to collaborate with the renowned Japanese producer, Foodman, and skillfully remixed his track "Moyashi Kids", accompanied by a psychedelic music video.
                        <br/><br/>
                        Ch4ins4w's self-titled album has also been recognized by industry insiders and tastemakers, with Ninja Tune listing it as one of their staff picks and Bandcamp featuring it on their new and notable page.
                        <br/><br/>
                        Known for his intricate rhythms, glitchy soundscapes, and experimental approach to production, Ch4ins4w is a true innovator in the world of electronic music. With each new release, he continues to push the boundaries of what's possible in the genre, creating music that is at once challenging and accessible, complex and deeply engaging.
                        <br/><br/>
                        Whether you're a fan of IDM, breakcore, footwork, or electronic music in general, Ch4ins4w is an artist that you won't want to miss. With his unique sound and innovative approach to production, he's quickly becoming one of the most exciting and talked-about artists in the underground electronic music scene.</p>
            </Fade>
             
            </div>

        </div>  );
    }
}
 
export default About;