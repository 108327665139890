import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import data from '../yourdata';

class Contact extends Component {
    state = {  }
    render() { 
        return (  <div className='contact'>
        <h1>
                <Fade bottom cascade href="https://linktr.ee/ch4ins4w">
                <div>
            {data.ShowContactImage ? <img src={data.contactImage} alt='Contact Image'></img> : null}     
                    </div></Fade>
                <Fade bottom cascade>
                <div>    
                    </div>Contact.</Fade>
        </h1>
        <Fade bottom>
        <div className='contact-content'>
                    <a href={`mailto:${data.contactEmail}`} className='email'>{data.contactEmail}</a>
                <br/>
                    <a 
                    className='link'
                    href="https://linktr.ee/ch4ins4w" 
                    target="__blank"
                >Linktree.</a>
        </div>
        </Fade>
        </div>);
    }
}
 
export default Contact;