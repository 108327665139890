import React, { Component } from 'react';
import data from '../yourdata';

class ChainsawGif extends Component {
    state = {  }
    render() { 
        return (
  <img src={data.chainGif} alt='3d Gif'></img> 
  );
};
}

export default ChainsawGif;