import t from './images/t.jpg'
import ch4ins4wgif from './images/ch4ins4w3.gif'
import ch4ins4w3dgif from './images/chainsaw3d-min.gif'
import about from './images/about4.png'
export default
    {
        //(Please Do Not Remove The comma(,) after every variable)
        //Change The Website Template
        name :'+',
        headerImage : t,
        ShowHeaderImage:true,
        contactImage: ch4ins4wgif,
        ShowContactImage: true,
        chainGif: ch4ins4w3dgif,
        ShowGif: true,
        //Contact Email
        contactEmail:'kalibrpluslabel@gmail.com',
        // Add Your About Text Here
        abouttext: "Ch4ins4w can be regarded as anything but your typical producer. Inspired by I.D.M., Vaporwave, Playstation Memory and Footwork. Ch4ins4w sets out to always be neck deep in an uncanny and, at times, euphoric waters. Never the one to shy away from taking left turns and injecting a healthy dose of humor into his productions, Ch4ins4w doesn’t seem to try to subvert expectations, but his catalog speak volumes to his love for the foreign and off-kilter.",
        aboutImage:about,
       //Change This To Hide The Image of About Section (True Or False)
       ShowAboutImage:true,//true or false (Change Here)
       ShowAboutImageTwo:true,//true or false (Change Here)
       // Change Projects Here 
       projects:[
           {
            title: 'Ch4ins4w',
            releaseDate: '2021',
            imageSrc: "https://f4.bcbits.com/img/a3171092978_10.jpg",
            url: 'https://kalibrplus.bandcamp.com/album/ch4ins4w-self-titled'
            },
            {
                id: 1,
                title:'Turbo Apocalypse', 
                releaseDate:'2020', 
                imageSrc:"https://f4.bcbits.com/img/a3943883318_16.jpg",
                url:'https://ch4ins4w.bandcamp.com/album/turbo-apocalypse'
            },
            {
                id: 2,
                title: '3D Island',
                releaseDate: '2018',
                imageSrc: "https://f4.bcbits.com/img/a3282446447_16.jpg",
                url: 'https://ch4ins4w.bandcamp.com/album/3d-island'
            }

        ]
    }